import { Public } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { DatePicker, DatePickerProps, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { SMALL_HORIZONTAL_SPACING } from '../theme';
import { getTimezoneAbbreviation } from '../utils/date-utils';

export interface ZonedDatePickerProps extends DatePickerProps<Date> {
  inputTimeZone: string;
}

export function ZonedDatePicker({ label, value, inputTimeZone, onChange, ...props }: ZonedDatePickerProps) {
  inputTimeZone = inputTimeZone || 'Etc/UTC';

  const handleDateChange = (newValue: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (!newValue && onChange) {
      onChange(newValue, context);
    }

    if (onChange) {
      const unzoned = newValue ? fromZonedTime(newValue, inputTimeZone) : null;
      onChange(unzoned, context);
    }
  };

  const zoned = value && inputTimeZone ? toZonedTime(value, inputTimeZone) : null;

  const tzLabel = label && typeof label === 'string' ? `${label} (${getTimezoneAbbreviation(inputTimeZone)})` : label;

  return (
    <Stack direction='row' spacing={SMALL_HORIZONTAL_SPACING} alignItems='center'>
      <DatePicker label={tzLabel} format='yyyy-MM-dd' value={zoned} onChange={handleDateChange} sx={{ flex: 1 }} {...props} />
      <Tooltip title={`${inputTimeZone} (${getTimezoneAbbreviation(inputTimeZone)})`}>
        <IconButton>
          <Public />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
