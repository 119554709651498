import { Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { JobData, useAdmin } from '../../../api';
import { Button, PageBody, PageContainer, PageHeader } from '../../../components';

interface JobsTableProps {
  loading: boolean;
  jobData: JobData[];
  onRun: (jobId: string) => void;
  style?: CSSProperties;
}
function JobsTable({ loading, jobData, onRun, style }: JobsTableProps) {
  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'name',
      headerName: 'Job',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'lastRun',
      headerName: 'Last Run',
      flex: 1,
      renderCell: (params) => {
        const job = params.row as JobData;

        return job.lastRun ? `${job.lastRun.toString()}` : 'Never';
      },
      sortComparator: (v1: Date | null, v2: Date | null) => {
        if (!v1) {
          return -1;
        }
        if (!v2) {
          return 1;
        }

        return v1.getTime() - v2.getTime();
      },
    },
    {
      display: 'flex',
      field: 'action',
      headerName: 'Action',
      renderCell: (params) => {
        const job = params.row as JobData;

        return (
          <Button disabled={loading} variant='contained' color='primary' onClick={() => onRun(job.id)}>
            Run
          </Button>
        );
      },
    },
  ];

  return (
    <DataGrid
      style={style}
      rows={jobData}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
        sorting: {
          sortModel: [{ field: 'lastRun', sort: 'desc' }],
        },
      }}
      hideFooterPagination
      pageSizeOptions={[100]}
    />
  );
}

export function AdminJobsPage() {
  const { fetchScheduledJobData: fetchJobData, runScheduledJob: runJob, refreshScheduledJobs: refreshJobs, scheduledJobData: jobData } = useAdmin();

  useEffect(() => {
    fetchJobData().catch((e) => {
      throw e;
    });
  }, [fetchJobData]);

  const [loading, setLoading] = useState(false);
  const run = useCallback(
    async (jobId: string) => {
      try {
        setLoading(true);
        await runJob(jobId);
      } finally {
        setLoading(false);
      }
    },
    [runJob]
  );

  return (
    <PageContainer>
      <PageHeader title='Admin - Jobs' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row'>
            <Button variant='contained' color='primary' onClick={() => refreshJobs()}>
              Refresh
            </Button>
          </Stack>
          <JobsTable loading={loading} jobData={jobData} onRun={run} />
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
