import { AxiosError } from 'axios';
import { endOfMonth } from 'date-fns';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useError } from '../components';
import { axios } from './axios-base';
import { AuthorType, Conversation, ConversationMessage, deserializeConversation, RenderType, SerializedConversation } from './conversation';
import { MessageType } from './conversation-stream';
import { convertFilesToFileDetails } from './document';
import { useSession } from './session';
import {
  Account,
  deserializeDocument,
  deserializeDocumentNote,
  deserializeTransaction,
  Document,
  DocumentNote,
  Journal,
  Province,
  SerializedDocument,
  SerializedDocumentNote,
  SerializedTx,
  Transaction,
  User,
  UserBankingDetails,
  UserPrivateDetails,
} from './shared-interfaces';

// Note: This probably should never change, but if it does, should be synced with conversation.service.ts
const OTTER_AUTHOR_ID = '5ea038e1-3a8b-48b2-97e4-22cf43ceef53';

export interface Checkpoint {
  id: string;
  balance: string;
  balanceCurrency: string;
  convertedBalance: string;
  journalBalance: string;
  journalCurrency: string;
  reconciled: boolean;
  difference: string;
  date: Date;
}

export enum AssetType {
  SMALL_TOOLS = 'SMALL_TOOLS',
  COMPUTER_EQUIPMENT_SOFTWARE = 'COMPUTER_EQUIPMENT_SOFTWARE',
  MOTOR_VEHICLES = 'MOTOR_VEHICLES',
  FURNITURE_AND_FIXTURES = 'FURNITURE_AND_FIXTURES',
  INCORPORATION_COSTS = 'INCORPORATION_COSTS',
  INTANGIBLE = 'INTANGIBLE',
}

export interface AssetSale {
  transactionId: string;
}

export interface Asset {
  id: string;
  organizationId: string;
  purchaseDate: string;
  purchasePrice: string;
  purchaseTransactionId: string;
  annualAmortizationRate: number;
  value: string;
  type: AssetType;
  quantity: number;
  soldQuantity: number;
  description: string;
  sales: AssetSale[];
}

export enum ScheduledOrgEventType {
  CHECK_IN = 'CHECK_IN',
  REIMBURSEMENT_APPROVAL = 'REIMBURSEMENT_APPROVAL',
}

export interface OrganizationEvent {
  id: string;
  organizationId: string;
  timestamp: Date;
  name: string;
  type: ScheduledOrgEventType;
  started: boolean;
  complete: boolean;
  parentId: string;
  path: string;
}

export enum JournalEntryLineType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface SerializedJournalEntry {
  id: string;
  date: string;
  dateTimestamp: string;
  reconciliationDate: string;
  reconciliationDateTimestamp: string;
  memo: string;
  tags: string[];
  debits: JournalEntryLine[];
  credits: JournalEntryLine[];
  index: number;
}

export interface JournalEntry {
  id: string;
  date: string;
  reconciliationDate: string;
  memo: string;
  tags: string[];
  debits: JournalEntryLine[];
  credits: JournalEntryLine[];
  index: number;
}

export function deserializeJournalEntry(journalEntry: SerializedJournalEntry) {
  const parsedDate = new Date(journalEntry.dateTimestamp);
  const parsedReconciliationDate = journalEntry.reconciliationDateTimestamp ? new Date(journalEntry.reconciliationDateTimestamp) : null;

  return {
    ...journalEntry,
    dateTimestamp: parsedDate,
    reconciliationDateTimestamp: parsedReconciliationDate || parsedDate,
  };
}

export interface JournalEntryLine {
  id: string;
  amount: string;
  type: JournalEntryLineType;
  accountId: string;
  description: string | null;
  foreignCurrencyAmount?: string;
  foreignCurrency?: string;
}

export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  CONNECTION_STALE = 'CONNECTION_STALE',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
}

export interface PlaidLinkFailure {
  id: string;
  organizationId: string;
  timestamp: Date;
  linkSessionId: string;
}

export enum OrganizationStatus {
  ANONYMOUS = 'ANONYMOUS',
  REGISTERED = 'REGISTERED',
  ONBOARDED = 'ONBOARDED',
  READY = 'READY',
}

export enum OrganizationType {
  SOLO = 'SOLO',
  STARTUP = 'STARTUP',
}

export interface Organization {
  id?: string;
  type: OrganizationType;
  name: string;
  legalName: string | null;
  businessNumber: string | null;
  payrollNumber: string | null;
  incorporationDate: string | null;
  description: string | null;
  firstOtterManagedFy: string | null;
  fyEndMonth: number;
  created: Date;
  defaultIncomeAccountType: string | null;
  status: OrganizationStatus;
  connectionStatus: {
    status: ConnectionStatus;
    connectionFailureDate?: Date;
  };
  emailWhitelist: EmailWhitelistEntry[];
  emailIdentifier: string | null;
  hasPayroll: boolean;
  checkInScanStart: Date | null;
  firstCheckInDate: Date | null;
  documentEmailCursor: Date | null;
  province: Province | null;
  timeZone: string;
}

export interface Transfer {
  id: string;
  organizationId: string;
  fromTransactionId: string;
  toTransactionId: string;
}

export interface Refund {
  id: string;
  organizationId: string;
  paymentTransactionId: string;
  refundTransactionId: string;
}

export interface TransactionDownloadOptions {
  ids?: string[];
  includeAssignedCategory?: boolean;
}

export interface CategorizationConversation {
  id: string;
  transactionId: string | null;
  documentId: string | null;
  conversationNumber: number;
  messages: ConversationMessage[];
}

export interface CategorizationMessage {
  id: string;
  author: string;
  content: string;
}

export enum FileArchiveStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum FileArchiveType {
  STORAGE_ZIP = 'STORAGE_ZIP',
}

export interface FileArchive {
  id?: string;
  organizationId: string;
  name: string;
  type: string;
  status: FileArchiveStatus;
  signedUrl: string | null;
  created: Date;
  updated: Date;
}

export interface Statement {
  id: string;
  name: string;
  organizationId: string;
  mimeType: string;
  externalAccountId: string;
  fileName: string;
  signedUrl: string;
  created: Date;
}

export interface TransactionDocumentMatch {
  id: string;
  transactionId: string;
  documentId: string;
  created: Date;
  matchStrength: number;
}

export enum MatchStatus {
  UNDER_REVIEW = 'UNDER_REVIEW',
  COMPLETE = 'COMPLETE',
}

export interface MatchGroup {
  id: string;
  status: MatchStatus;
  matches: TransactionDocumentMatch[];
  totalMismatchResolution: TotalMismatchResolution | null;
  autoApproved: boolean;
}

export enum TotalMismatchResolution {
  FX = 'FX',
  FEES = 'FEES',
  NONE = 'NONE',
}

export interface TrialBalance {
  accountBalances: Array<{
    accountId: string;
    accountName: string;
    standaloneDebit: string | null;
    standaloneCredit: string | null;
    consolidatedDebit: string | null;
    consolidatedCredit: string | null;
  }>;
  total: {
    debit: string | null;
    credit: string | null;
  };
}

export interface EmailWhitelistEntry {
  id?: string;
  type: string;
  value: string;
}

export interface OpeningBalances {
  [accountId: string]: string;
}

export interface JobData {
  id: string;
  name: string;
  lastRun: Date | null;
}

export interface TransactionImportPreview {
  transactions: Transaction[];
  duplicateMap: { [transactionId: string]: Transaction };
  openingBalance?: {
    date: Date;
    currency: string;
    amount: string;
  } | null;
  closingBalance?: {
    date: Date;
    currency: string;
    amount: string;
  } | null;
}

export interface CreateJournalEntryArgs {
  date: string;
  memo: string;
  debits: Array<{
    amount: string;
    accountId: string;
    currency: string;
  }>;
  credits: Array<{
    amount: string;
    accountId: string;
    currency: string;
  }>;
  isOpeningBalance: boolean;
}

export enum AsyncJobStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
export interface AsyncJob {
  id: string;
  status: AsyncJobStatus;
  created: Date;
}

export interface TransactionImport extends AsyncJob {
  statement: {
    url: string;
    type: string;
    fileName: string;
  } | null;
}

export interface PayrollConfig {
  nmbrCompanyId: string;
}

export interface UpdateTransactionErrors {
  updateTransactionError: boolean;
  matchExists?: boolean;
}

export interface UpdateTransactionErrorResolutions {
  matchExists?: 'unmatch';
}

export interface AnnualGeneralCalculations {
  totalRevenue: string;
  totalSales: string;
  salesTotalsByMonth: { [month: number]: string };
  salesTotalsByCurrency: {
    [currency: string]: {
      original: string;
      converted: string;
    };
  };
  salesGrowthByMonth: { [month: number]: string };
  accountsPayable: {
    total: string;
    documents: Array<Document>;
    documentAges: {
      [documentId: string]: number | null;
    };
    journalEntries: Array<JournalEntry>;
  };
  accountsReceivable: {
    total: string;
    documents: Array<Document>;
    documentAges: {
      [documentId: string]: number | null;
    };
    journalEntries: Array<JournalEntry>;
  };
  netIncome: string;
  netProfit: string;
  gstHstOwing: string;
  incomeTaxOwing: string;
  averageBankCharge: string;
  prepaidExpenses: Array<{
    document: Document;
    numberOfPayments: number;
    completedPayments: number;
    period: string;
  }>;
  smallestExpense: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestExpense: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  smallestSale: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestSale: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  repeatClients: { [client: string]: number };
  bestSalesMonth: {
    month: number;
    total: string;
    percentVsAvg: string;
  };
  worstSalesMonth: {
    month: number;
    total: string;
    percentVsAvg: string;
  };
  monthlySalesAverage: string;
  subscriptions: Array<{
    name: string;
    currency: string;
    amount: string;
    convertedAmount: string;
  }>;
  expenseTotalsByMonthAndAccount: {
    [month: number]: {
      [accountId: string]: string;
    };
  };
  expenseTotalsByCurrency: {
    [currency: string]: {
      original: string;
      converted: string;
    };
  };
  revenueTotalsByMonthAndAccount: {
    [month: number]: {
      [accountId: string]: string;
    };
  };
}

export type SerializedAnnualGeneralCalculations = Omit<AnnualGeneralCalculations, 'accountsReceivable' | 'accountsPayable'> & {
  accountsReceivable: {
    total: string;
    documents: SerializedDocument[];
    documentAges: {
      [documentId: string]: number | null;
    };
    journalEntries: SerializedJournalEntry[];
  };
  accountsPayable: {
    total: string;
    documents: SerializedDocument[];
    documentAges: {
      [documentId: string]: number | null;
    };
    journalEntries: SerializedJournalEntry[];
  };
  smallestExpense: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestExpense: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  smallestSale: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestSale: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
};

export interface AnnualStartupCalculations {
  cashBalances: {
    [accountId: string]: {
      balance: string;
      previousFyBalance: string;
      growth: number | string;
    };
  };
  burnRate: string;
  runway: string;
  totalCash: string;
  opex: string;
  opexAsRevenuePercentage: string;
  grossMargin: string;
  operatingMargin: string;
  ebitdaMargin: string;
  netIncomeMargin: string;
  growthRate: string;
}

export type SerializedAnnualStartupCalculations = AnnualStartupCalculations;

export interface SerializedAnnualCalculations {
  general: SerializedAnnualGeneralCalculations;
  startup?: SerializedAnnualStartupCalculations;
}

export interface AnnualCalculations {
  general: AnnualGeneralCalculations;
  startup?: AnnualStartupCalculations;
}

export interface MonthlyGeneralCalculations {
  totalRevenue: string;
  totalSales: string;
  netIncome: string;
  totalExpenses: string;
  expenseTotals: {
    [accountId: string]: {
      amount: string;
      percent: number;
    };
  };
  expenseGrowth: {
    [accountId: string]: string;
  };
  smallestExpense: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestExpense: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  smallestSale: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestSale: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  gstHstOwing: string;
  incomeTaxOwing: string;
  repeatClients: {
    [client: string]: {
      monthCount: number;
      annualCount: number;
    };
  };
  subscriptions: Array<{
    name: string;
    currency: string;
    amount: string;
    convertedAmount: string;
  }>;
  salesByClient: { [client: string]: string };
}

export type SerializedMonthlyGeneralCalculations = Omit<
  MonthlyGeneralCalculations,
  'smallestExpense' | 'largestExpense' | 'smallestSale' | 'largestSale'
> & {
  smallestExpense: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestExpense: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  smallestSale: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  largestSale: null | {
    transaction?: SerializedTx;
    document?: SerializedDocument;
    journalEntry?: SerializedJournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
};

export interface MonthlyStartupCalculations {
  cashBalances: {
    [accountId: string]: {
      balance: string;
      previousMonthBalance: string;
      growth: number | string;
    };
  };
  burnRate: string;
  runway: string;
  totalCash: string;
  opex: string;
  opexAsRevenuePercentage: string;
  grossMargin: string;
  operatingMargin: string;
  ebitdaMargin: string;
  netIncomeMargin: string;
  growthRate: string;
}

export type SerializedMonthlyStartupCalulations = MonthlyStartupCalculations;

export interface MonthlyCalculations {
  general: MonthlyGeneralCalculations;
  startup: MonthlyStartupCalculations | undefined;
}

export type SerializedMonthlyCalculations = {
  general: SerializedMonthlyGeneralCalculations;
  startup: SerializedMonthlyStartupCalulations | undefined;
};

export interface DocumentFileWithHint {
  file: File;
  transactionMatchHint: string;
}

export interface IAdminContext {
  fetchOrganizations: () => Promise<void>;
  fetchOrganizationUsers: (organizationId: string) => Promise<void>;
  fetchJournals: (organizationId: string) => Promise<void>;
  fetchJournalEntries: (journalId: string) => Promise<void>;
  fetchJournalAccounts: (journalId: string) => Promise<void>;
  fetchJournalAccountCheckpoints: (journalId: string, accountId: string) => Promise<void>;
  fetchTransactions: (organizationId: string) => Promise<void>;
  fetchTransactionCategorizations: (transactionId: string) => Promise<void>;
  fetchDuplicateTransactions: (organizationId: string) => Promise<void>;
  fetchDocumentCategorizations: (documentId: string) => Promise<void>;
  fetchDocuments: (organizationId: string) => Promise<void>;
  fetchDocumentsMissingTransactions: (organizationId: string) => Promise<void>;
  fetchDocumentNotes: (organizationId: string, documentId: string) => Promise<void>;
  fetchUnreviewedDocumentCounts: (organizationId: string) => Promise<void>;
  fetchTransactionDocumentMatches: (organizationId: string) => Promise<void>;
  fetchTransactionsMissingDocuments: (organizationId: string) => Promise<void>;
  fetchUnreviewedTransactionCounts: (organizationId: string) => Promise<void>;
  fetchOwner: (organizationId: string) => Promise<void>;
  fetchOpeningBalances: (organizationId: string) => Promise<void>;
  fetchScheduledJobData: () => Promise<void>;
  fetchConversations: (organizationId: string) => Promise<void>;
  fetchTransfers: (organizationId: string) => Promise<void>;
  fetchRefunds: (organiztionId: string) => Promise<void>;
  fetchPlaidLinkSessionFailures: (organizationId: string) => Promise<void>;
  fetchStatements: (organizationId: string) => Promise<void>;
  fetchFileArchives: (organizationId: string) => Promise<void>;
  fetchPayrollConfig: (organizationId: string) => Promise<void>;
  fetchCalculations: (organizationId: string, fy: string, month: number | null) => Promise<void>;
  fetchOrganizationEvents: (year: number, month: number) => Promise<void>;
  fetchOrganizationDocumentEmails: (organizationId: string, emailCursor: Date | null) => Promise<unknown[]>;
  fetchAssets: (organizationId: string) => Promise<void>;
  fetchJournalAccountReconciliations: (journalId: string) => Promise<void>;
  fetchJournalAccountCoverage: (journalId: string, accountId: string) => Promise<void>;
  fetchJournalAccountCoverageGaps: (journalId: string, accountId: string) => Promise<void>;
  reconcileAccount: (
    organizationId: string,
    journalId: string,
    accountId: string,
    reconciliationData: {
      ignoreTransactionIds: string[];
      unignoreTransactionIds: string[];
    }
  ) => Promise<void>;
  updateJournal: (organizationId: string, journalId: string, updates: Partial<Journal>) => Promise<void>;
  getUserPrivateDetails: (userId: string) => Promise<UserPrivateDetails>;
  getUserBankingDetails: (userId: string) => Promise<UserBankingDetails>;
  getUserTd1Files: (userId: string) => Promise<{
    federal: string | null;
    provincial: string | null;
  }>;
  predictLearningAccountMatch: (transactionId: string) => Promise<{
    account: Account;
    percentageMatch: number;
  }>;
  uploadDocuments: (organizationId: string, files: File[] | DocumentFileWithHint[]) => Promise<void>;
  currencyConvert: (date: Date, amount: string, fromCurrency: string, toCurrency: string) => Promise<string>;
  activatePayroll: (organizationId: string) => Promise<void>;
  createJournalEntry: (journalId: string, entry: CreateJournalEntryArgs) => Promise<void>;
  reverseJournalEntry: (journalId: string, entryId: string) => Promise<void>;
  createTransactionDocumentMatches: (
    organizationId: string,
    matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[],
    totalMismatchResolution?: TotalMismatchResolution
  ) => Promise<Array<{ type: string; transactionTotal?: string; documentTotal?: string }> | null>;
  previewTransactionDocumentMatches: (
    organizationId: string,
    matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[]
  ) => Promise<Array<{ type: string; transactionTotal?: string; documentTotal?: string }> | null>;
  updateMatchGroup: (organizationId: string, changes: Partial<MatchGroup> & { id: string }) => Promise<void>;
  deleteTransactionDocumentMatches: (
    organizationId: string,
    matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[]
  ) => Promise<void>;
  autoMatch: (organizationId: string) => Promise<void>;
  autoMatchScores: (
    organizationId: string,
    documentIds: string[],
    transactionIds: string[]
  ) => Promise<{
    documentRawMatchScores: {
      [documentId: string]: {
        name: number;
        date: number;
        amount: number;
        matchHints: number;
      };
    };
    documentMatchWeights: {
      [documentId: string]: {
        name: number;
        date: number;
        amount: number;
        matchHints: number;
      };
    };
    documentWeightedMatchScores: {
      [documentId: string]: {
        name: number;
        amount: number;
        date: number;
        matchHints: number;
        overall: number;
      };
    };
    transactionRawMatchScores: {
      [transactionId: string]: {
        name: number;
        amount: number;
        date: number;
        matchHints: number;
      };
    };
    transactionMatchWeights: {
      [transactionId: string]: {
        name: number;
        date: number;
        amount: number;
        matchHints: number;
      };
    };
    transactionWeightedMatchScores: {
      [transactionId: string]: {
        name: number;
        amount: number;
        date: number;
        matchHints: number;
        overall: number;
      };
    };
  }>;
  createTrialBalance: (journalId: string) => Promise<void>;
  downloadTrialBalance: (journalId: string) => Promise<void>;
  downloadFinancialStatements: (journalId: string, type: string, period?: number) => Promise<void>;
  updateOrganizationDetails: (
    organizationId: string,
    details: {
      name?: string;
      type?: OrganizationType;
      legalName?: string;
      businessNumber?: string;
      incorporationDate?: string;
      firstOtterManagedFy?: string;
      description?: string;
      fyEndMonth?: number;
      defaultIncomeAccountType?: string;
      status?: OrganizationStatus;
      emailWhitelist?: { type: string; value: string }[];
      documentEmailAddress?: string;
      checkInScanStart?: Date | null;
      firstCheckInDate?: Date | null;
      province?: Province | null;
    }
  ) => Promise<void>;
  updateOpeningBalances: (organizationId: string, openingBalances: { [accountId: string]: string }) => Promise<void>;
  updateOwnerDetails: (ownerId: string, details: Partial<User>) => Promise<void>;
  updateUser: (organizationId: string, userId: string, updates: Partial<User>) => Promise<void>;
  updateTransaction: (
    organizationId: string,
    transactionId: string,
    transaction: Partial<Transaction>,
    errorResolutions?: UpdateTransactionErrorResolutions
  ) => Promise<void>;
  reprocessTransaction: (organizationId: string, transactionId: string) => Promise<void>;
  createTransfer: (organizationId: string, journalId: string, fromTransactionId: string, toTransactionId: string) => Promise<void>;
  createRefund: (organizationId: string, journalId: string, paymentTransactionId: string, refundTransactionId: string) => Promise<void>;
  updateDocument: (organizationId: string, documentId: string, document: Partial<Document>) => Promise<void>;
  createDocumentNote: (organizationId: string, documentId: string, note: string) => Promise<void>;
  updateStatement: (organizationId: string, statementId: string, statement: Partial<Statement>) => Promise<void>;
  runScheduledJob: (id: string) => Promise<void>;
  refreshScheduledJobs: () => Promise<void>;
  startImpersonatedConversation: (args: {
    organizationId: string;
    conversationId: string;
    title: string;
    message?: string;
    strategy?: string;
  }) => Promise<void>;
  addImpersonatedMessageToConversation: (organizationId: string, conversationId: string, message: string) => Promise<void>;
  createTransactionImportPreview: (
    organizationId: string,
    statementFile: File,
    statementType: string,
    accountId: string
  ) => Promise<TransactionImportPreview>;
  importTransactions: ({
    organizationId,
    transactions,
    accountId,
    statementFile,
    ignoreWarnings,
    openingBalance,
    closingBalance,
  }: {
    organizationId: string;
    transactions: Transaction[];
    accountId: string;
    statementFile: File;
    ignoreWarnings: boolean;
    openingBalance?: {
      date: Date;
      amount: string;
      currency: string;
    };
    closingBalance?: {
      date: Date;
      amount: string;
      currency: string;
    };
  }) => Promise<{ duplicateStatement?: boolean } | null>;
  fetchTransactionImports: (organizationId: string) => Promise<void>;
  downloadTransactions: (organizationId: string, journalId: string, options?: TransactionDownloadOptions) => Promise<void>;
  downloadAccount: (journalId: string, accountId: string, options?: { onlyIncludeCurrentEntries: boolean }) => Promise<void>;
  downloadAccounts: (journalId: string, options?: { onlyIncludeCurrentEntries: boolean }) => Promise<void>;
  prepareDocumentArchive: (organizationId: string) => Promise<void>;
  createJournalAccount: (
    journalId: string,
    createAccountParams: {
      name: string;
      type: string;
      cardType?: string;
      description: string;
      accountNumber?: string;
      parentId?: string;
      currency: string;
    }
  ) => Promise<void>;
  updateJournalAccount: (journalId: string, account: Partial<Account> & { id: string; cardType?: string | null }) => Promise<void>;
  deleteJournalAccount: (journalId: string, accountId: string) => Promise<void>;
  organizations: Organization[] | null;
  users: { [organizationId: string]: User[] };
  payrollConfig: { [organizationId: string]: PayrollConfig };
  openingBalances: { [organizationId: string]: OpeningBalances };
  owners: { [organizationId: string]: User };
  journals: { [organizationId: string]: Journal[] };
  journalEntries: { [journalId: string]: JournalEntry[] };
  journalAccounts: { [journalId: string]: Account[] };
  journalAccountReconciliations: {
    [journalId: string]: Array<{
      accountId: string;
      journalBalance: string;
      checkpointBalance: string | null;
      difference: string | null;
      lastCheckpoint: Date | null;
      issues: {
        unreconciled?: boolean;
        needsCheckpoint?: boolean;
      };
    }>;
  };
  journalAccountCoverage: {
    [accountId: string]: Array<{
      start: Date;
      end: Date;
    }>;
  };
  journalAccountCoverageGaps: {
    [accountId: string]: Array<{
      start: Date;
      end: Date;
    }>;
  };
  journalAccountCheckpoints: {
    [accountId: string]: Array<Checkpoint>;
  };
  transactions: { [organizationId: string]: Transaction[] };
  duplicateTransactions: {
    [organizationId: string]: {
      transactions: Transaction[];
      duplicateMap: { [transactionId: string]: Transaction };
    };
  };
  transactionCategorizations: { [transactionId: string]: CategorizationConversation[] };
  documentCategorizations: { [documentId: string]: CategorizationConversation[] };
  documents: { [organizationId: string]: Document[] };
  documentsMissingTransactions: { [organizationId: string]: Set<string> };
  documentNotes: { [documentId: string]: DocumentNote[] };
  unreviewedDocumentCounts: {
    [organizationId: string]: {
      [fy: string]: number;
    };
  };
  transactionDocumentMatches: { [organizationId: string]: MatchGroup[] };
  transactionsMissingDocuments: { [organizationId: string]: Set<string> };
  unreviewedTransactionCounts: {
    [organizationId: string]: {
      [fy: string]: number;
    };
  };
  trialBalances: { [journalId: string]: TrialBalance };
  scheduledJobData: JobData[];
  conversations: { [organizationId: string]: Conversation[] };
  calculations: {
    [organizationId: string]: {
      annual: {
        [period: string]: AnnualCalculations;
      };
      monthly: {
        [period: string]: MonthlyCalculations;
      };
    };
  };
  transfers: { [organizationId: string]: Transfer[] };
  refunds: { [organizationId: string]: Refund[] };
  plaidLinkSessionFailures: { [organizationId: string]: PlaidLinkFailure[] };
  statements: { [organizationId: string]: Statement[] };
  fileArchives: { [organizationId: string]: FileArchive[] };
  transactionImports: { [organizationId: string]: TransactionImport[] };
  organizationEvents: { [year: number]: { [month: number]: OrganizationEvent[] } };
  assets: { [organizationId: string]: Asset[] };
}

function useAdminData() {
  const { showError } = useError();
  const { sessionDiff } = useSession();
  const [journals, setJournals] = useState<{ [organizationId: string]: Journal[] }>({});
  const [journalEntries, setJournalEntries] = useState<{ [journalId: string]: JournalEntry[] }>({});
  const [journalAccounts, setJournalAccounts] = useState<{ [journalId: string]: Account[] }>({});
  const [journalAccountReconciliations, setJournalAccountReconciliations] = useState<{
    [journalId: string]: Array<{
      accountId: string;
      journalBalance: string;
      checkpointBalance: string | null;
      difference: string | null;
      lastCheckpoint: Date | null;
      issues: {
        unreconciled?: boolean;
        needsCheckpoint?: boolean;
      };
    }>;
  }>({});
  const [journalAccountCoverage, setJournalAccountCoverage] = useState<{
    [accountId: string]: Array<{
      start: Date;
      end: Date;
    }>;
  }>({});
  const [journalAccountCoverageGaps, setJournalAccountCoverageGaps] = useState<{
    [accountId: string]: Array<{
      start: Date;
      end: Date;
    }>;
  }>({});
  const [journalAccountCheckpoints, setJournalAccountCheckpoints] = useState<{
    [accountId: string]: Array<Checkpoint>;
  }>({});
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [users, setUsers] = useState<{ [organizationId: string]: User[] }>({});
  const [openingBalances, setOpeningBalances] = useState<{ [organizationId: string]: OpeningBalances }>({});
  const [owners, setOwners] = useState<{ [organizationId: string]: User }>({});
  const [transactions, setTransactions] = useState<{ [organizationId: string]: Transaction[] }>({});
  const [duplicateTransactions, setDuplicateTransactions] = useState<{
    [organizationId: string]: {
      transactions: Transaction[];
      duplicateMap: { [transactionId: string]: Transaction };
    };
  }>({});
  const [transfers, setTransfers] = useState<{ [organizationId: string]: Transfer[] }>({});
  const [refunds, setRefunds] = useState<{ [organizationId: string]: Refund[] }>({});
  const [transactionCategorizations, setTransactionCategorizations] = useState<{
    [transactionId: string]: CategorizationConversation[];
  }>({});
  const [documentCategorizations, setDocumentCategorizations] = useState<{ [documentId: string]: CategorizationConversation[] }>({});
  const [documents, setDocuments] = useState<{ [organizationId: string]: Document[] }>({});
  const [documentsMissingTransactions, setDocumentsMissingTransactions] = useState<{ [organizationId: string]: Set<string> }>({});
  const [documentNotes, setDocumentNotes] = useState<{ [documentId: string]: DocumentNote[] }>({});
  const [unreviewedDocumentCounts, setUnreviewedDocumentCounts] = useState<{
    [organizationId: string]: {
      [fy: string]: number;
    };
  }>({});
  const [transactionDocumentMatches, setTransactionDocumentMatches] = useState<{ [organizationId: string]: MatchGroup[] }>({});
  const [transactionsMissingDocuments, setTransactionsMissingDocuments] = useState<{ [organizationId: string]: Set<string> }>({});
  const [unreviewedTransactionCounts, setUnreviewedTransactionCounts] = useState<{
    [organizationId: string]: {
      [fy: string]: number;
    };
  }>({});
  const [trialBalances, setTrialBalances] = useState<{ [journalId: string]: TrialBalance }>({});
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [conversations, setConversations] = useState<{ [organizationId: string]: Conversation[] }>({});
  const [plaidLinkFailures, setPlaidLinkFailures] = useState<{ [organizationId: string]: PlaidLinkFailure[] }>({});
  const [statements, setStatements] = useState<{ [organizationId: string]: Statement[] }>({});
  const [fileArchives, setFileArchives] = useState<{ [organizationId: string]: FileArchive[] }>({});
  const [transactionImports, setTransactionImports] = useState<{ [organizationId: string]: TransactionImport[] }>({});
  const [calculations, setCalculations] = useState<{
    [organizationId: string]: {
      annual: {
        [period: string]: AnnualCalculations;
      };
      monthly: {
        [period: string]: MonthlyCalculations;
      };
    };
  }>({});
  const [payrollConfig, setPayrollConfig] = useState<{ [organizationId: string]: PayrollConfig }>({});
  const [organizationEvents, setOrganizationEvents] = useState<{ [year: number]: { [month: number]: OrganizationEvent[] } }>({});
  const [assets, setAssets] = useState<{ [organizationId: string]: Asset[] }>({});

  useEffect(() => {
    if (sessionDiff.old && !sessionDiff.new) {
      setJournals({});
      setJournalEntries({});
      setJournalAccounts({});
      setJournalAccountReconciliations({});
      setJournalAccountCoverage({});
      setJournalAccountCheckpoints({});
      setOrganizations(null);
      setUsers({});
      setOpeningBalances({});
      setOwners({});
      setTransactions({});
      setUnreviewedTransactionCounts({});
      setDuplicateTransactions({});
      setTransfers({});
      setRefunds({});
      setTransactionCategorizations({});
      setDocumentCategorizations({});
      setDocuments({});
      setDocumentsMissingTransactions({});
      setDocumentNotes({});
      setUnreviewedDocumentCounts({});
      setTransactionDocumentMatches({});
      setTransactionsMissingDocuments({});
      setTrialBalances({});
      setConversations({});
      setPlaidLinkFailures({});
      setStatements({});
      setFileArchives({});
      setTransactionImports({});
      setCalculations({});
      setPayrollConfig({});
      setOrganizationEvents({});
      setAssets({});
    }
  }, [sessionDiff]);

  const currencyConvert = useCallback(
    async (date: Date, amount: string, fromCurrency: string, toCurrency: string) => {
      try {
        const response = await axios.post(`/admin/currency-convert`, {
          date,
          amount,
          fromCurrency,
          toCurrency,
        });

        const data = response.data as { convertedAmount: string };

        return data.convertedAmount;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchOrganizationEvents = useCallback(
    async (year: number, month: number) => {
      try {
        const startDate = new Date(year, month - 1, 1);
        const endDate = endOfMonth(startDate);
        const response = await axios.get(`/admin/organization-events?start=${startDate.toISOString()}&end=${endDate.toISOString()}`);

        const data = response.data as { events: OrganizationEvent[] };

        const converted = data.events.map((e) => ({
          ...e,
          timestamp: new Date(e.timestamp),
        }));

        setOrganizationEvents((existing) => ({
          ...existing,
          [year]: {
            ...existing[year],
            [month]: converted,
          },
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchPayrollConfig = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/payroll/config`);
        const config = response.data as PayrollConfig;

        setPayrollConfig((existing) => ({
          ...existing,
          [organizationId]: config,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournals = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/journals`);
        const journalsData = response.data as {
          journals: (Omit<Journal, 'fyStart' | 'fyEnd' | 'archived'> & { fyStart: string; fyEnd: string; archived: string })[];
        };

        const converted = journalsData.journals.map((j) => ({
          ...j,
          fyStart: new Date(j.fyStart),
          fyEnd: new Date(j.fyEnd),
          archived: j.archived ? new Date(j.archived) : null,
        }));

        setJournals((existing) => ({
          ...existing,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournalAccountCoverage = useCallback(
    async (journalId: string, accountId: string) => {
      try {
        const response = await axios.get(`/admin/journals/${journalId}/accounts/${accountId}/coverage`);
        const coverageData = response.data as {
          coverage: Array<{
            start: string;
            end: string;
          }>;
        };

        const converted = coverageData.coverage.map((c) => ({
          start: new Date(c.start),
          end: new Date(c.end),
        }));

        setJournalAccountCoverage((current) => ({
          ...current,
          [accountId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournalAccountCoverageGaps = useCallback(
    async (journalId: string, accountId: string) => {
      try {
        const response = await axios.get(`/admin/journals/${journalId}/accounts/${accountId}/coverage-gaps`);
        const coverageData = response.data as {
          coverageGaps: Array<{
            start: string;
            end: string;
          }>;
        };

        const converted = coverageData.coverageGaps.map((c) => ({
          start: new Date(c.start),
          end: new Date(c.end),
        }));

        setJournalAccountCoverageGaps((current) => ({
          ...current,
          [accountId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournalAccountReconciliations = useCallback(
    async (journalId: string) => {
      try {
        const response = await axios.get(`/admin/journals/${journalId}/account-reconciliations`);
        const reconciliationData = response.data as {
          accountReconciliations: Array<{
            accountId: string;
            journalBalance: string;
            checkpointBalance: string | null;
            difference: string | null;
            lastCheckpoint: string | null;
            issues: {
              unreconciled?: boolean;
              needsCheckpoint?: boolean;
            };
          }>;
        };

        const converted = reconciliationData.accountReconciliations.map((r) => ({
          ...r,
          lastCheckpoint: r.lastCheckpoint ? new Date(r.lastCheckpoint) : null,
        }));

        setJournalAccountReconciliations((current) => ({
          ...current,
          [journalId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournalEntries = useCallback(
    async (journalId: string) => {
      try {
        const response = await axios.get(`/admin/journals/${journalId}/entries`);
        const journalEntriesData = response.data as { journalEntries: SerializedJournalEntry[] };

        const formattedJournalEntries = journalEntriesData.journalEntries.map(deserializeJournalEntry);

        setJournalEntries((existingEntries) => ({
          ...existingEntries,
          [journalId]: formattedJournalEntries,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await axios.get('/admin/organizations');
      const organizationsData = response.data as { organizations: (Omit<Organization, 'created'> & { created: number })[] };

      const converted = organizationsData.organizations.map((o) => ({
        ...o,
        created: new Date(o.created),
        connectionStatus: {
          ...o.connectionStatus,
          connectionFailureDate: o.connectionStatus.connectionFailureDate
            ? new Date(o.connectionStatus.connectionFailureDate)
            : o.connectionStatus.connectionFailureDate,
        },
        checkInScanStart: o.checkInScanStart ? new Date(o.checkInScanStart) : null,
        firstCheckInDate: o.firstCheckInDate ? new Date(o.firstCheckInDate) : null,
        documentEmailCursor: o.documentEmailCursor ? new Date(o.documentEmailCursor) : null,
      }));

      setOrganizations(converted);
    } catch (e) {
      showError(e as Error);
      throw e;
    }
  }, [showError]);

  const fetchOrganizationUsers = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/users`);

        const userData = response.data as { users: User[] };

        setUsers((existing) => ({
          ...existing,
          [organizationId]: userData.users,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchOpeningBalances = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/opening-balances`);
        const balanceData = response.data as { [accountId: string]: string };

        setOpeningBalances((existing) => ({
          ...existing,
          [organizationId]: balanceData,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchOwner = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/owner`);
        const ownerData = response.data as { owner: User };

        setOwners((existing) => ({
          ...existing,
          [organizationId]: ownerData.owner,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournalAccounts = useCallback(
    async (journalId: string) => {
      try {
        const response = await axios.get(`/admin/journals/${journalId}/accounts`);
        const accountData = response.data as { accounts: Account[] };
        setJournalAccounts((existing) => ({
          ...existing,
          [journalId]: accountData.accounts,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchJournalAccountCheckpoints = useCallback(
    async (journalId: string, accountId: string) => {
      try {
        const response = await axios.get(`/admin/journals/${journalId}/accounts/${accountId}/checkpoints`);
        const checkpointData = response.data as {
          checkpoints: Array<{
            id: string;
            balance: string;
            balanceCurrency: string;
            convertedBalance: string;
            journalBalance: string;
            journalCurrency: string;
            difference: string;
            reconciled: boolean;
            date: string;
          }>;
        };

        const converted = checkpointData.checkpoints.map((b) => ({
          ...b,
          date: new Date(b.date),
        }));

        setJournalAccountCheckpoints((current) => ({
          ...current,
          [accountId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchTransactions = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/transactions`);
        const transactionData = response.data as {
          transactions: (Omit<Transaction, 'date' | 'postedDate'> & { date: string; postedDate: string })[];
        };

        const converted = transactionData.transactions.map((t) => ({
          ...t,
          date: new Date(t.date),
          postedDate: new Date(t.postedDate),
        }));

        setTransactions((existing) => ({
          ...existing,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchDuplicateTransactions = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/duplicate-transactions`);
        const transactionData = response.data as {
          transactions: (Omit<Transaction, 'date' | 'postedDate'> & { date: string; postedDate: string })[];
          duplicateMap: { [transactionId: string]: Omit<Transaction, 'date' | 'postedDate'> & { date: string; postedDate: string } };
        };

        const converted = {
          transactions: transactionData.transactions.map((t) => ({
            ...t,
            date: new Date(t.date),
            postedDate: new Date(t.postedDate),
          })),
          duplicateMap: Object.entries(transactionData.duplicateMap).reduce(
            (map, current) => {
              const [transactionId, transaction] = current;

              map[transactionId] = {
                ...transaction,
                date: new Date(transaction.date),
                postedDate: new Date(transaction.postedDate),
              };

              return map;
            },
            {} as { [transactionId: string]: Transaction }
          ),
        };

        setDuplicateTransactions((existing) => ({
          ...existing,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchTransactionCategorizations = useCallback(
    async (transactionId: string) => {
      try {
        const response = await axios.get(`/admin/transactions/${transactionId}/categorizations`);
        const data = response.data as { categorizations: CategorizationConversation[] };

        setTransactionCategorizations((existing) => ({
          ...existing,
          [transactionId]: data.categorizations,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchDocumentCategorizations = useCallback(
    async (documentId: string) => {
      try {
        const response = await axios.get(`/admin/documents/${documentId}/categorizations`);
        const data = response.data as { categorizations: CategorizationConversation[] };

        setDocumentCategorizations((existing) => ({
          ...existing,
          [documentId]: data.categorizations,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchDocuments = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/documents`);
        const documentData = response.data as {
          documents: SerializedDocument[];
        };

        const converted = documentData.documents.map(deserializeDocument);

        setDocuments((existing) => ({
          ...existing,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchUnreviewedDocumentCounts = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/unreviewed-document-counts`);

        const counts = response.data as {
          [fy: string]: number;
        };

        setUnreviewedDocumentCounts((existing) => ({
          ...existing,
          [organizationId]: counts,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchDocumentsMissingTransactions = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/documents-missing-transactions`);

        const transactionIds = response.data as { documentIds: string[] };

        setDocumentsMissingTransactions((existing) => ({
          ...existing,
          [organizationId]: new Set(transactionIds.documentIds),
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchDocumentNotes = useCallback(
    async (organizationId: string, documentId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/documents/${documentId}/notes`);
        const documentNotesData = response.data as {
          notes: SerializedDocumentNote[];
        };

        const converted = documentNotesData.notes.map(deserializeDocumentNote);

        setDocumentNotes((existing) => ({
          ...existing,
          [documentId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchTransactionDocumentMatches = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/matches`);
        const matchData = response.data as {
          matches: Array<
            Omit<MatchGroup, 'matches' | 'created' | 'updated'> & {
              created: string;
              updated: string;
              matches: Array<Omit<TransactionDocumentMatch, 'created'> & { created: string }>;
            }
          >;
        };
        const converted = matchData.matches.map((m) => ({
          ...m,
          created: new Date(m.created),
          updated: new Date(m.updated),
          matches: m.matches.map((ma) => ({
            ...ma,
            created: new Date(ma.created),
          })),
        }));

        setTransactionDocumentMatches((existing) => ({
          ...existing,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchTransactionsMissingDocuments = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/transactions-missing-documents`);
        const data = response.data as { transactionsMissingDocuments: Transaction[] };

        const idSet = new Set<string>();
        for (const transaction of data.transactionsMissingDocuments) {
          idSet.add(transaction.id);
        }

        setTransactionsMissingDocuments((existing) => ({
          ...existing,
          [organizationId]: idSet,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchUnreviewedTransactionCounts = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/unreviewed-transaction-counts`);

        const counts = response.data as {
          [fy: string]: number;
        };

        setUnreviewedTransactionCounts((existing) => ({
          ...existing,
          [organizationId]: counts,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchScheduledJobData = useCallback(async () => {
    try {
      const response = await axios.get(`/admin/scheduled-jobs`);
      const jobData = response.data as { id: string; name: string; lastRun: string }[];

      const converted = jobData.map((jd) => ({
        ...jd,
        lastRun: jd.lastRun ? new Date(jd.lastRun) : null,
      }));

      setJobData(converted);
    } catch (e) {
      showError(e as Error);
      throw e;
    }
  }, [showError]);

  const fetchConversations = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/conversations`);
        const conversationsData = response.data as { conversations: SerializedConversation[] };

        const converted = conversationsData.conversations.map((c) => deserializeConversation(c));

        setConversations((existing) => ({
          ...existing,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchTransfers = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/transfers`);

        const data = response.data as { transfers: Transfer[] };

        setTransfers((existing) => ({
          ...existing,
          [organizationId]: data.transfers,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchRefunds = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/refunds`);

        const data = response.data as { refunds: Refund[] };

        setRefunds((existing) => ({
          ...existing,
          [organizationId]: data.refunds,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchPlaidLinkSessionFailures = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/plaid-link-failures`);

        const data = response.data as { plaidLinkFailures: (Omit<PlaidLinkFailure, 'timestamp'> & { timestamp: string })[] };

        setPlaidLinkFailures((existing) => ({
          ...existing,
          [organizationId]: data.plaidLinkFailures.map((f) => ({
            ...f,
            timestamp: new Date(f.timestamp),
          })),
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchStatements = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/statements`);

        const data = response.data as { statements: Array<Omit<Statement, 'created'> & { created: string }> };

        setStatements((current) => ({
          ...current,
          [organizationId]: data.statements.map((s) => ({
            ...s,
            created: new Date(s.created),
          })),
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchTransactionImports = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/transaction-imports`);

        const data = response.data as { transactionImports: Array<Omit<TransactionImport, 'created'> & { created: string }> };
        const converted = data.transactionImports.map((i) => ({
          ...i,
          created: new Date(i.created),
        }));

        setTransactionImports((current) => ({
          ...current,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchCalculations = useCallback(
    async (organizationId: string, fy: string, month: number | null) => {
      try {
        const response = await axios.post(`/admin/organizations/${organizationId}/calculations`, { fy, month });

        if (month) {
          const calculationData = response.data as SerializedMonthlyCalculations;

          const converted: MonthlyCalculations = {
            ...calculationData,
            general: {
              ...calculationData.general,
              smallestExpense: calculationData.general.smallestExpense
                ? {
                    ...calculationData.general.smallestExpense,
                    transaction: calculationData.general.smallestExpense.transaction
                      ? deserializeTransaction(calculationData.general.smallestExpense.transaction)
                      : undefined,
                    document: calculationData.general.smallestExpense.document
                      ? deserializeDocument(calculationData.general.smallestExpense.document)
                      : undefined,
                    journalEntry: calculationData.general.smallestExpense.journalEntry
                      ? deserializeJournalEntry(calculationData.general.smallestExpense.journalEntry)
                      : undefined,
                    amount: calculationData.general.smallestExpense.amount,
                    percentageOfTotal: calculationData.general.smallestExpense.percentageOfTotal,
                  }
                : null,
              largestExpense: calculationData.general.largestExpense
                ? {
                    ...calculationData.general.largestExpense,
                    transaction: calculationData.general.largestExpense?.transaction
                      ? deserializeTransaction(calculationData.general.largestExpense.transaction)
                      : undefined,
                    document: calculationData.general.largestExpense?.document
                      ? deserializeDocument(calculationData.general.largestExpense.document)
                      : undefined,
                    journalEntry: calculationData.general.largestExpense?.journalEntry
                      ? deserializeJournalEntry(calculationData.general.largestExpense.journalEntry)
                      : undefined,
                    amount: calculationData.general.largestExpense.amount,
                    percentageOfTotal: calculationData.general.largestExpense.percentageOfTotal,
                  }
                : null,
              smallestSale: calculationData.general.smallestSale
                ? {
                    ...calculationData.general.smallestSale,
                    transaction: calculationData.general.smallestSale.transaction
                      ? deserializeTransaction(calculationData.general.smallestSale.transaction)
                      : undefined,
                    document: calculationData.general.smallestSale.document
                      ? deserializeDocument(calculationData.general.smallestSale.document)
                      : undefined,
                    journalEntry: calculationData.general.smallestSale.journalEntry
                      ? deserializeJournalEntry(calculationData.general.smallestSale.journalEntry)
                      : undefined,
                    amount: calculationData.general.smallestSale.amount,
                    percentageOfTotal: calculationData.general.smallestSale.percentageOfTotal,
                  }
                : null,
              largestSale: calculationData.general.largestSale
                ? {
                    ...calculationData.general.largestSale,
                    transaction: calculationData.general.largestSale?.transaction
                      ? deserializeTransaction(calculationData.general.largestSale.transaction)
                      : undefined,
                    document: calculationData.general.largestSale?.document
                      ? deserializeDocument(calculationData.general.largestSale.document)
                      : undefined,
                    journalEntry: calculationData.general.largestSale?.journalEntry
                      ? deserializeJournalEntry(calculationData.general.largestSale.journalEntry)
                      : undefined,
                    amount: calculationData.general.largestSale.amount,
                    percentageOfTotal: calculationData.general.largestSale.percentageOfTotal,
                  }
                : null,
            },
          };

          const key = month ? `${fy}-${month}` : fy;
          setCalculations((current) => {
            const calculationsCopy = {
              ...current,
              [organizationId]: {
                monthly: {
                  ...current[organizationId]?.monthly,
                  [key]: converted,
                },
                annual: {
                  ...current[organizationId]?.annual,
                },
              },
            };

            return calculationsCopy;
          });
        } else {
          const calculationData = response.data as SerializedAnnualCalculations;

          const converted = {
            ...calculationData,
            general: {
              ...calculationData.general,
              accountsReceivable: {
                total: calculationData.general.accountsReceivable.total,
                documents: calculationData.general.accountsReceivable.documents.map(deserializeDocument),
                documentAges: calculationData.general.accountsReceivable.documentAges,
                journalEntries: calculationData.general.accountsReceivable.journalEntries.map(deserializeJournalEntry),
              },
              accountsPayable: {
                total: calculationData.general.accountsPayable.total,
                documents: calculationData.general.accountsPayable.documents.map(deserializeDocument),
                documentAges: calculationData.general.accountsPayable.documentAges,
                journalEntries: calculationData.general.accountsPayable.journalEntries.map(deserializeJournalEntry),
              },
              smallestExpense: calculationData.general.smallestExpense
                ? {
                    ...calculationData.general.smallestExpense,
                    transaction: calculationData.general.smallestExpense.transaction
                      ? deserializeTransaction(calculationData.general.smallestExpense.transaction)
                      : undefined,
                    document: calculationData.general.smallestExpense.document
                      ? deserializeDocument(calculationData.general.smallestExpense.document)
                      : undefined,
                    journalEntry: calculationData.general.smallestExpense.journalEntry
                      ? deserializeJournalEntry(calculationData.general.smallestExpense.journalEntry)
                      : undefined,
                    amount: calculationData.general.smallestExpense.amount,
                    percentageOfTotal: calculationData.general.smallestExpense.percentageOfTotal,
                  }
                : null,
              largestExpense: calculationData.general.largestExpense
                ? {
                    ...calculationData.general.largestExpense,
                    transaction: calculationData.general.largestExpense?.transaction
                      ? deserializeTransaction(calculationData.general.largestExpense.transaction)
                      : undefined,
                    document: calculationData.general.largestExpense?.document
                      ? deserializeDocument(calculationData.general.largestExpense.document)
                      : undefined,
                    journalEntry: calculationData.general.largestExpense?.journalEntry
                      ? deserializeJournalEntry(calculationData.general.largestExpense.journalEntry)
                      : undefined,
                    amount: calculationData.general.largestExpense.amount,
                    percentageOfTotal: calculationData.general.largestExpense.percentageOfTotal,
                  }
                : null,
              smallestSale: calculationData.general.smallestSale
                ? {
                    ...calculationData.general.smallestSale,
                    transaction: calculationData.general.smallestSale.transaction
                      ? deserializeTransaction(calculationData.general.smallestSale.transaction)
                      : undefined,
                    document: calculationData.general.smallestSale.document
                      ? deserializeDocument(calculationData.general.smallestSale.document)
                      : undefined,
                    journalEntry: calculationData.general.smallestSale.journalEntry
                      ? deserializeJournalEntry(calculationData.general.smallestSale.journalEntry)
                      : undefined,
                    amount: calculationData.general.smallestSale.amount,
                    percentageOfTotal: calculationData.general.smallestSale.percentageOfTotal,
                  }
                : null,
              largestSale: calculationData.general.largestSale
                ? {
                    ...calculationData.general.largestSale,
                    transaction: calculationData.general.largestSale?.transaction
                      ? deserializeTransaction(calculationData.general.largestSale.transaction)
                      : undefined,
                    document: calculationData.general.largestSale?.document
                      ? deserializeDocument(calculationData.general.largestSale.document)
                      : undefined,
                    journalEntry: calculationData.general.largestSale?.journalEntry
                      ? deserializeJournalEntry(calculationData.general.largestSale.journalEntry)
                      : undefined,
                    amount: calculationData.general.largestSale.amount,
                    percentageOfTotal: calculationData.general.largestSale.percentageOfTotal,
                  }
                : null,
            },
          };

          const key = month ? `${fy}-${month}` : fy;
          setCalculations((current) => {
            const calculationsCopy = {
              ...current,
              [organizationId]: {
                monthly: {
                  ...current[organizationId]?.monthly,
                },
                annual: {
                  ...current[organizationId]?.annual,
                  [key]: converted,
                },
              },
            };

            return calculationsCopy;
          });
        }
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const fetchOrganizationDocumentEmails = useCallback(
    async (organizationId: string, emailCursor: Date | null) => {
      try {
        const response = await axios.post(`/admin/organizations/${organizationId}/document-emails`, { documentEmailCursor: emailCursor });

        return response.data as unknown[];
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const reconcileAccount = useCallback(
    async (
      organizationId: string,
      journalId: string,
      accountId: string,
      reconciliationData: {
        ignoreTransactionIds: string[];
        unignoreTransactionIds: string[];
      }
    ) => {
      try {
        await axios.post(`/admin/journals/${journalId}/accounts/${accountId}/reconcile`, reconciliationData);

        await Promise.all([
          fetchTransactions(organizationId),
          fetchJournalEntries(journalId),
          fetchJournalAccountReconciliations(journalId),
          fetchJournalAccountCheckpoints(journalId, accountId),
        ]);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchTransactions, fetchJournalEntries, fetchJournalAccountCheckpoints, fetchJournalAccountReconciliations]
  );

  const getUserPrivateDetails = useCallback(
    async (userId: string) => {
      try {
        const response = await axios.get(`/admin/users/${userId}/user-private-details`);

        return response.data as UserPrivateDetails;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const getUserBankingDetails = useCallback(
    async (userId: string) => {
      try {
        const response = await axios.get(`/admin/users/${userId}/user-banking-details`);

        return response.data as UserBankingDetails;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const getUserTd1Files = useCallback(
    async (userId: string) => {
      try {
        const response = await axios.get(`/admin/users/${userId}/td1-files`);

        return response.data as {
          federal: string | null;
          provincial: string | null;
        };
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const predictLearningAccountMatch = useCallback(
    async (transactionId: string) => {
      try {
        const response = await axios.post(`/admin/predict-learning-account-match`, { transactionId });

        return response.data as {
          account: Account;
          percentageMatch: number;
        };
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const uploadDocuments = useCallback(
    async (organizationId: string, files: File[] | DocumentFileWithHint[]) => {
      try {
        if (!files.length) {
          throw new Error('No riles provided for upload');
        }

        const convertedFiles = await convertFilesToFileDetails(files as File[]);

        const transactionMatchHints = [] as Array<{
          index: number;
          transactionMatchHint: string;
        }>;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if ((file as DocumentFileWithHint).transactionMatchHint) {
            transactionMatchHints.push({
              index: i,
              transactionMatchHint: (file as DocumentFileWithHint).transactionMatchHint,
            });
          }
        }

        await axios.post(`/admin/organizations/${organizationId}/upload-documents`, {
          files: convertedFiles,
          transactionMatchHints,
        });
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const createTrialBalance = useCallback(
    async (journalId: string) => {
      try {
        const response = await axios.post(`/admin/journals/${journalId}/trial-balance`, {});

        const trialBalance = response.data as TrialBalance;

        setTrialBalances((existing) => ({
          ...existing,
          [journalId]: trialBalance,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const downloadTrialBalance = useCallback(
    async (journalId: string) => {
      try {
        const response = await axios.post(
          `/admin/journals/${journalId}/trial-balance-download`,
          {},
          {
            responseType: 'blob',
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'trial-balance.csv'); // Set the file name for download

        // Append to html page and click it
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const downloadFinancialStatements = useCallback(
    async (journalId: string, type: string, period?: number) => {
      try {
        let endpointUrl = `/admin/journals/${journalId}/download-financial-statements?type=${type}`;
        if (period) {
          endpointUrl += `&period=${period}`;
        }
        const response = await axios.get(endpointUrl, {
          responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'] as string;
        let fileName = 'financial-statements.pdf'; // Default filename

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const match = disposition.match(/filename="([^"]+)"/);
          if (match && match[1]) {
            fileName = match[1];
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html page and click it
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const activatePayroll = useCallback(
    async (organizationId: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/payroll/activate`, {});
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const updateJournal = useCallback(
    async (organizationId: string, journalId: string, updates: Partial<Journal>) => {
      try {
        await axios.put(`/admin/journals/${journalId}`, updates);
        await fetchJournals(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchJournals]
  );

  const createJournalEntry = useCallback(
    async (journalId: string, entry: CreateJournalEntryArgs) => {
      try {
        await axios.post(`/admin/journals/${journalId}/entries`, entry);
        await fetchJournalEntries(journalId);
        await createTrialBalance(journalId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchJournalEntries, createTrialBalance, showError]
  );

  const reverseJournalEntry = useCallback(
    async (journalId: string, entryId: string) => {
      try {
        await axios.post(`/admin/journals/${journalId}/entries/${entryId}/reverse`);

        await fetchJournalEntries(journalId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchJournalEntries]
  );

  const createTransactionDocumentMatches = useCallback(
    async (
      organizationId: string,
      matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[],
      totalMismatchResolution?: TotalMismatchResolution
    ) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/matches`, {
          matches,
          totalMismatchResolution,
        });

        await Promise.all([
          fetchTransactionDocumentMatches(organizationId),
          fetchTransactionsMissingDocuments(organizationId),
          fetchDocumentsMissingTransactions(organizationId),
        ]);

        return null;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchTransactionDocumentMatches, fetchTransactionsMissingDocuments, fetchDocumentsMissingTransactions, showError]
  );

  const previewTransactionDocumentMatches = useCallback(
    async (organizationId: string, matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[]) => {
      try {
        const response = await axios.post(`/admin/organizations/${organizationId}/preview-matches`, {
          matches,
        });

        const responseBody = response.data as { warnings: Array<{ type: string }> };

        if (responseBody.warnings) {
          return responseBody.warnings;
        }

        return null;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const updateMatchGroup = useCallback(
    async (organizationId: string, changes: Partial<MatchGroup> & { id: string }) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/match-groups/${changes.id}`, changes);
        await fetchTransactionDocumentMatches(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchTransactionDocumentMatches, showError]
  );

  const deleteTransactionDocumentMatches = useCallback(
    async (organizationId: string, matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[]) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/delete-matches`, {
          matches,
        });
        await Promise.all([
          fetchTransactionDocumentMatches(organizationId),
          fetchTransactionsMissingDocuments(organizationId),
          fetchDocumentsMissingTransactions(organizationId),
        ]);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchTransactionDocumentMatches, fetchTransactionsMissingDocuments, fetchDocumentsMissingTransactions, showError]
  );

  const autoMatch = useCallback(
    async (organizationId: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/auto-match`, {});
        await fetchTransactionDocumentMatches(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchTransactionDocumentMatches, showError]
  );

  const autoMatchScores = useCallback(
    async (organizationId: string, documentIds: string[], transactionIds: string[]) => {
      try {
        const result = await axios.post(`/admin/organizations/${organizationId}/auto-match-score`, {
          documentIds,
          transactionIds,
        });

        return result.data as {
          documentRawMatchScores: {
            [documentId: string]: {
              name: number;
              date: number;
              amount: number;
              matchHints: number;
            };
          };
          documentMatchWeights: {
            [documentId: string]: {
              name: number;
              date: number;
              amount: number;
              matchHints: number;
            };
          };
          documentWeightedMatchScores: {
            [documentId: string]: {
              name: number;
              amount: number;
              date: number;
              matchHints: number;
              overall: number;
            };
          };
          transactionRawMatchScores: {
            [transactionId: string]: {
              name: number;
              amount: number;
              date: number;
              matchHints: number;
            };
          };
          transactionMatchWeights: {
            [transactionId: string]: {
              name: number;
              date: number;
              amount: number;
              matchHints: number;
            };
          };
          transactionWeightedMatchScores: {
            [transactionId: string]: {
              name: number;
              amount: number;
              date: number;
              matchHints: number;
              overall: number;
            };
          };
        };
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const updateOrganizationDetails = useCallback(
    async (
      organizationId: string,
      details: {
        name?: string;
        type?: OrganizationType;
        legalName?: string;
        businessNumber?: string;
        incorporationDate?: string;
        firstOtterManagedFy?: string;
        description?: string;
        fyEndMonth?: number;
        defaultIncomeAccountType?: string;
        status?: OrganizationStatus;
        emailWhitelist?: { type: string; value: string }[];
        documentEmailAddress?: string;
        checkInScanStart?: Date | null;
        firstCheckInDate?: Date | null;
        province?: Province | null;
      }
    ) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}`, details);
        await fetchOrganizations();
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchOrganizations, showError]
  );

  const updateOpeningBalances = useCallback(
    async (organizationId: string, openingBalances: { [accountId: string]: string }) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/opening-balances`, openingBalances);
        await fetchOpeningBalances(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchOpeningBalances, showError]
  );

  const updateOwnerDetails = useCallback(
    async (organizationId: string, details: Partial<User>) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/owner`, details);
        await fetchOwner(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchOwner, showError]
  );

  const updateUser = useCallback(
    async (organizationId: string, userId: string, updates: Partial<User>) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/users/${userId}`, updates);
        await fetchOrganizationUsers(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchOrganizationUsers, showError]
  );

  const updateTransaction = useCallback(
    async (organizationId: string, transactionId: string, updates: Partial<Transaction>, errorResolutions?: UpdateTransactionErrorResolutions) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/transactions/${transactionId}`, { updates, errorResolutions });

        await Promise.all([fetchTransactions(organizationId), fetchRefunds(organizationId), fetchTransfers(organizationId)]);
      } catch (e) {
        const err = e as AxiosError<UpdateTransactionErrors>;
        if (err.response?.data.updateTransactionError) {
          throw err.response.data;
        } else {
          showError(e as Error);
          throw e;
        }
      }
    },
    [fetchTransactions, showError, fetchTransfers, fetchRefunds]
  );

  const reprocessTransaction = useCallback(
    async (organizationId: string, transactionId: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/transactions/${transactionId}/reprocess`, {});

        await Promise.all([fetchTransactions(organizationId), fetchRefunds(organizationId), fetchTransfers(organizationId)]);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchTransactions, fetchTransfers, fetchRefunds]
  );

  const createTransfer = useCallback(
    async (organizationId: string, journalId: string, fromTransactionId: string, toTransactionId: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/transfers`, {
          journalId,
          fromTransactionId,
          toTransactionId,
        });

        await Promise.all([fetchTransfers(organizationId), fetchTransactions(organizationId), fetchJournalEntries(journalId)]);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchTransactions, fetchJournalEntries, fetchTransfers]
  );

  const createRefund = useCallback(
    async (organizationId: string, journalId: string, paymentTransactionId: string, refundTransactionId: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/refunds`, {
          journalId,
          paymentTransactionId,
          refundTransactionId,
        });

        await Promise.all([fetchRefunds(organizationId), fetchTransactions(organizationId), fetchJournalEntries(journalId)]);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchTransactions, fetchJournalEntries, fetchRefunds]
  );

  const updateDocument = useCallback(
    async (organizationId: string, documentId: string, updates: Partial<Document>) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/documents/${documentId}`, updates);
        await fetchDocuments(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchDocuments, showError]
  );

  const createDocumentNote = useCallback(
    async (organizationId: string, documentId: string, note: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/documents/${documentId}/notes`, {
          note,
        });

        await fetchDocumentNotes(organizationId, documentId);
        await fetchDocuments(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchDocumentNotes, fetchDocuments]
  );

  const updateStatement = useCallback(
    async (organizationId: string, statementId: string, changes: Partial<Statement>) => {
      try {
        await axios.put(`/admin/organizations/${organizationId}/statements/${statementId}`, changes);
        await fetchStatements(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchStatements, showError]
  );

  const runJob = useCallback(
    async (id: string) => {
      try {
        await axios.post(`/admin/scheduled-jobs/${id}`, {});
        await fetchScheduledJobData();
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchScheduledJobData, showError]
  );

  const refreshJobs = useCallback(async () => {
    try {
      await axios.post('/admin/refresh-scheduled-jobs', {});
      await fetchScheduledJobData();
    } catch (e) {
      showError(e as Error);
      throw e;
    }
  }, [fetchScheduledJobData, showError]);

  const startImpersonatedConversation = useCallback(
    async ({
      organizationId,
      conversationId,
      title,
      message,
      strategy,
    }: {
      organizationId: string;
      conversationId: string;
      title: string;
      message?: string;
      strategy?: string;
    }) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/conversations/${conversationId}`, {
          title,
          message,
          strategy,
        });

        await fetchConversations(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchConversations, showError]
  );

  const createTransactionImportPreview = useCallback(
    async (organizationId: string, statementFile: File, statementType: string, accountId: string) => {
      try {
        const formData = new FormData();
        formData.append('file', statementFile);
        formData.append('statementType', statementType);
        formData.append('accountId', accountId);

        const result = await axios.post(`/admin/organizations/${organizationId}/transaction-import-preview`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const data = result.data as {
          transactions: (Omit<Transaction, 'date' | 'postedDate'> & { date: string; postedDate: string })[];
          duplicateMap: { [transactionId: string]: Omit<Transaction, 'date' | 'postedDate'> & { date: string; postedDate: string } };
          openingBalance?: {
            date: string;
            currency: string;
            amount: string;
          } | null;
          closingBalance?: {
            date: string;
            currency: string;
            amount: string;
          } | null;
        };

        const formatted = {
          transactions: data.transactions.map((t) => ({
            ...t,
            date: new Date(t.date),
            postedDate: new Date(t.postedDate),
          })),
          duplicateMap: Object.entries(data.duplicateMap).reduce(
            (map, [transactionId, duplicate]) => {
              map[transactionId] = {
                ...duplicate,
                date: new Date(duplicate.date),
                postedDate: new Date(duplicate.postedDate),
              };
              return map;
            },
            {} as { [transactionId: string]: Transaction }
          ),
          openingBalance: data.openingBalance
            ? {
                date: new Date(data.openingBalance.date),
                currency: data.openingBalance.currency,
                amount: data.openingBalance.amount,
              }
            : null,
          closingBalance: data.closingBalance
            ? {
                date: new Date(data.closingBalance.date),
                currency: data.closingBalance.currency,
                amount: data.closingBalance.amount,
              }
            : null,
        };

        return formatted;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const importTransactions = useCallback(
    async ({
      organizationId,
      transactions,
      accountId,
      statementFile,
      ignoreWarnings,
      openingBalance,
      closingBalance,
    }: {
      organizationId: string;
      transactions: Transaction[];
      accountId: string;
      statementFile: File;
      ignoreWarnings: boolean;
      openingBalance?: {
        amount: string;
        currency: string;
        date: Date;
      };
      closingBalance?: {
        amount: string;
        currency: string;
        date: Date;
      };
    }) => {
      try {
        const formData = new FormData();
        formData.append('file', statementFile);
        formData.append('transactions', JSON.stringify(transactions));
        formData.append('accountExternalId', accountId);
        formData.append('ignoreWarnings', ignoreWarnings.toString());
        if (openingBalance) {
          formData.append('openingBalance', JSON.stringify(openingBalance));
        }
        if (closingBalance) {
          formData.append('closingBalance', JSON.stringify(closingBalance));
        }

        const response = await axios.post(`/admin/organizations/${organizationId}/import-file-transactions`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const responseBody = response.data as
          | {
              duplicateStatement?: boolean;
            }
          | undefined;

        if (responseBody) {
          return responseBody;
        }

        await Promise.all([fetchTransactions(organizationId), fetchTransactionImports(organizationId)]);

        return null;
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchTransactions, fetchTransactionImports, showError]
  );

  const downloadTransactions = useCallback(
    async (organizationId: string, journalId: string, options?: TransactionDownloadOptions) => {
      try {
        const params = [];
        if (options?.ids) {
          params.push(options.ids.map((id) => `id=${encodeURIComponent(id)}`).join('&'));
        }
        if (options?.includeAssignedCategory) {
          params.push(`includeAssignedCategory=${String(options.includeAssignedCategory)}`);
        }

        const response = await axios.get(
          `/admin/organizations/${organizationId}/transactions/download-csv?journalId=${journalId}${params.length ? '&' + params.join('&') : ''}`,
          {
            responseType: 'blob',
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'transactions.csv'); // Set the file name for download

        // Append to html page and click it
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const conversationsById = useMemo(() => {
    return Object.values(conversations).reduce(
      (map, current) => {
        for (const conversation of current) {
          map[conversation.id] = conversation;
        }
        return map;
      },
      {} as { [conversationId: string]: Conversation }
    );
  }, [conversations]);

  const addImpersonatedMessageToConversation = useCallback(
    async (organizationId: string, conversationId: string, message: string) => {
      try {
        await axios.put(`/admin/conversations/${conversationId}`, {
          message,
        });

        setConversations((existing) => {
          const existingOrgConversations = existing[organizationId];
          const existingConversation = conversationsById[conversationId];

          const newMessages: ConversationMessage[] = [
            ...existingConversation.messages,
            {
              id: uuid(),
              author: OTTER_AUTHOR_ID,
              authorType: AuthorType.SYSTEM,
              renderType: RenderType.TEXT,
              messageType: MessageType.TEXT,
              content: message,
              isHidden: false,
              created: new Date(),
            },
          ];

          const newConversations = [];
          for (const conversation of existingOrgConversations) {
            if (conversation.id !== conversationId) {
              newConversations.push(conversation);
            } else {
              newConversations.push({
                ...existingConversation,
                messages: newMessages,
              });
            }
          }

          return {
            ...existing,
            [organizationId]: newConversations,
          };
        });
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [conversationsById, showError]
  );

  const downloadAccount = useCallback(
    async (journalId: string, accountId: string, options?: { onlyIncludeCurrentEntries: boolean }) => {
      try {
        const params = [];
        if (options?.onlyIncludeCurrentEntries) {
          params.push(`onlyIncludeCurrentEntries=${String(options.onlyIncludeCurrentEntries)}`);
        }

        const response = await axios.get(
          `/admin/journals/${journalId}/accounts/${accountId}/download-csv${params.length ? '?' + params.join('&') : ''}`,
          {
            responseType: 'blob',
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'account.csv');

        // Append to html page and click it
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const downloadAccounts = useCallback(
    async (journalId: string, options?: { onlyIncludeCurrentEntries: boolean }) => {
      try {
        const params = [];
        if (options?.onlyIncludeCurrentEntries) {
          params.push(`onlyIncludeCurrentEntries=${String(options.onlyIncludeCurrentEntries)}`);
        }

        const response = await axios.get(`/admin/journals/${journalId}/accounts/download-csv${params.length ? '?' + params.join('&') : ''}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'accounts.zip'); // Set the file name for download

        // Append to html page and click it
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const createJournalAccount = useCallback(
    async (
      journalId: string,
      createAccountParams: {
        name: string;
        type: string;
        description: string;
        accountNumber?: string;
        parentId?: string;
        currency: string;
      }
    ) => {
      try {
        await axios.post(`/admin/journals/${journalId}/accounts`, createAccountParams);

        await fetchJournalAccounts(journalId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchJournalAccounts, showError]
  );

  const updateJournalAccount = useCallback(
    async (journalId: string, updates: Partial<Account> & { id: string }) => {
      try {
        await axios.put(`/admin/journals/${journalId}/accounts/${updates.id}`, updates);

        await fetchJournalAccounts(journalId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchJournalAccounts, showError]
  );

  const deleteJournalAccount = useCallback(
    async (journalId: string, accountId: string) => {
      try {
        await axios.delete(`/admin/journals/${journalId}/accounts/${accountId}`);

        await fetchJournalAccounts(journalId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [fetchJournalAccounts, showError]
  );

  const fetchFileArchives = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/file-archives`);

        const archives = response.data as { archives: Array<FileArchive> };

        const converted = archives.archives.map((a) => ({
          ...a,
          created: new Date(a.created),
          updated: new Date(a.updated),
        }));

        setFileArchives((current) => ({
          ...current,
          [organizationId]: converted,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const prepareDocumentArchive = useCallback(
    async (organizationId: string) => {
      try {
        await axios.post(`/admin/organizations/${organizationId}/prepare-document-archive`, {});

        await fetchFileArchives(organizationId);
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError, fetchFileArchives]
  );

  const fetchAssets = useCallback(
    async (organizationId: string) => {
      try {
        const response = await axios.get(`/admin/organizations/${organizationId}/assets`);

        const assetData = response.data as {
          assets: Asset[];
        };

        setAssets((existing) => ({
          ...existing,
          [organizationId]: assetData.assets,
        }));
      } catch (e) {
        showError(e as Error);
        throw e;
      }
    },
    [showError]
  );

  const journalContext: IAdminContext = {
    fetchJournals,
    fetchOrganizations,
    fetchOrganizationUsers,
    fetchOpeningBalances,
    fetchOwner,
    fetchJournalEntries,
    fetchJournalAccounts,
    fetchJournalAccountCoverage,
    fetchJournalAccountCoverageGaps,
    fetchJournalAccountCheckpoints,
    fetchTransactions,
    fetchDuplicateTransactions,
    fetchTransactionCategorizations,
    fetchDocumentCategorizations,
    fetchDocuments,
    fetchDocumentsMissingTransactions,
    fetchDocumentNotes,
    fetchUnreviewedDocumentCounts,
    fetchTransactionDocumentMatches,
    fetchTransactionsMissingDocuments,
    fetchUnreviewedTransactionCounts,
    fetchScheduledJobData: fetchScheduledJobData,
    fetchConversations,
    fetchTransfers,
    fetchRefunds,
    fetchPlaidLinkSessionFailures,
    fetchStatements,
    fetchFileArchives,
    fetchTransactionImports,
    fetchCalculations,
    fetchPayrollConfig,
    fetchOrganizationEvents,
    fetchOrganizationDocumentEmails,
    fetchAssets,
    fetchJournalAccountReconciliations,
    reconcileAccount,
    updateJournal,
    getUserPrivateDetails,
    getUserBankingDetails,
    getUserTd1Files,
    predictLearningAccountMatch,
    uploadDocuments,
    currencyConvert,
    activatePayroll,
    createJournalEntry,
    reverseJournalEntry,
    createTransactionDocumentMatches,
    previewTransactionDocumentMatches,
    updateMatchGroup,
    deleteTransactionDocumentMatches,
    autoMatch,
    autoMatchScores,
    createTrialBalance,
    downloadTrialBalance,
    downloadFinancialStatements,
    updateOrganizationDetails,
    updateOwnerDetails,
    updateUser,
    updateOpeningBalances,
    updateTransaction,
    reprocessTransaction,
    createRefund,
    createTransfer,
    updateDocument,
    createDocumentNote,
    updateStatement,
    runScheduledJob: runJob,
    refreshScheduledJobs: refreshJobs,
    startImpersonatedConversation,
    addImpersonatedMessageToConversation,
    createTransactionImportPreview,
    importTransactions,
    downloadTransactions,
    downloadAccount,
    downloadAccounts,
    createJournalAccount,
    updateJournalAccount,
    deleteJournalAccount,
    prepareDocumentArchive,
    journals,
    journalEntries,
    journalAccounts,
    journalAccountCoverage,
    journalAccountCoverageGaps,
    journalAccountCheckpoints,
    journalAccountReconciliations,
    organizations,
    users,
    openingBalances,
    owners,
    transactions,
    unreviewedTransactionCounts,
    duplicateTransactions,
    transactionCategorizations,
    documentCategorizations,
    documents,
    documentsMissingTransactions,
    documentNotes,
    unreviewedDocumentCounts,
    transactionDocumentMatches,
    transactionsMissingDocuments,
    trialBalances,
    scheduledJobData: jobData,
    conversations,
    transfers,
    refunds,
    plaidLinkSessionFailures: plaidLinkFailures,
    statements,
    fileArchives,
    transactionImports,
    calculations,
    payrollConfig,
    organizationEvents,
    assets,
  };

  return journalContext;
}

export const AdminContext = createContext<IAdminContext>({} as IAdminContext);

export const useAdmin = () => useContext(AdminContext);

export const AdminContextProvider = ({ children }: { children: React.ReactNode }) => {
  const admin = useAdminData();

  return <AdminContext.Provider value={admin}>{children}</AdminContext.Provider>;
};
